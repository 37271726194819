<template lang="pug">
  div
    p.title.primary--text.px-4.pt-6 Choose a game

    Divider
    v-list.py-0(dense)
      template(v-for="game, id, i in games")
        Divider(v-if="i", :inset="72")
        v-list-item(:to="`/${game.id}/search?userCardCountByRarePrimary=${game.amount[1] / 2}`")
          v-list-item-avatar
            v-img(:src="game.icon")
          v-list-item-content
            v-list-item-title.text-wrap
              span {{game.fullName}} 
              v-list-item-action-text Server: {{game.region}}
          v-icon mdi-chevron-right
    Divider

    v-alert.mx-4.mt-4(type="success", dense)
      | Project Sekai Shop is upgraded to 
      a(href="https://shop.amaoto.moe", target="_blank") Amaoto's Gaming Shop
      | . Please visit the new website for a better shopping experience.

    v-footer(app, padless)
      v-card.text-center.transparent(tile, style="width: 100%")
        v-card-text
          router-link(to="/order") Order
          span  | 
          a(href="mailto:shop@pjsekai.moe") Contact

</template>

<script>
import Divider from '@/components/Divider';
import games from '@/games';

export default {
  name: 'Home',

  components: { Divider },

  data() {
    return {
      games,
    };
  }
};
</script>
